import React, { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'

import '../Componant Style/addProduct.css';

import imageAdd from '../assets/imageAdd.svg';
import remove from '../assets/remove.svg';
import addIcon from '../assets/addIcon.svg';
import suppcat from '../assets/annulerCat.svg';
import x from '../assets/X.svg';

export default function AddCat() {

  const navigate = useNavigate()

  const fileInputRef = useRef(null);
  const [name, setName] = useState(''); 
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [viewImages, setViewImages] = useState(false);
  const [subcategories, setSubcategories] = useState(['']);
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState('');

  if (selectedFiles.length > 0 && !viewImages) {
    setViewImages(true);
  } else if (selectedFiles.length === 0 && viewImages) {
    setViewImages(false);
  }

  const handleFileChange = (event) => {
    const files = event.target.files;

    if (files.length + selectedFiles.length < 2) {
      if (files.length > 0 && selectedFiles.length === 0) {
        const fileList = Array.from(files);
        setSelectedFiles(fileList);
      } else if (files.length > 0 && selectedFiles.length > 0) {
        const fileList = Array.from(files);
        const updatedSelectedFiles = selectedFiles.concat(fileList);
        setSelectedFiles(updatedSelectedFiles);
      }
    } else {
      alert('Max is 1 picture');
    }
  };


  const containsUndefinedOrEmpty = (arr) => {
    // Check if the array is defined and not null
    if (arr && Array.isArray(arr)) {
      // Check if the array is empty
      if (arr.length === 0) {
        return true;
      }
  
      // Use some() method to check if any element is undefined, empty, or contains only white spaces
      return arr.some((element) => element === undefined || element.trim() === '');
    } else {
      // If the array is not defined or not an array, return true
      return true;
    }
  };

  const isEmptyArray = (arr) => {
    return arr.length === 0;
  };

  const handleButtonClick = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const addSubcategory = () => {
    setSubcategories([...subcategories, { id: Date.now(), value: '' }]);
  };

  const changeSubcategory = (id, value) => {
    const updatedSubcategories = subcategories.map((item) =>
      item.id === id ? { ...item, value } : item
    );
    setSubcategories(updatedSubcategories);
  };

  const removeSubcategory = (id) => {
    const updatedSubcategories = subcategories.filter((item) => item.id !== id);
    setSubcategories(updatedSubcategories);
  };

  const changeBrand = (e) => {
    setBrand(e.target.value);
  };

  const addBrand = () => {
    setBrands([...brands, brand.trim('')]);
    setBrand('');
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if(brand.trim()===''){
        alert("can't add empty brand")
      }else{
        addBrand();
      }
    }
};


  const removeBrand = (index) => {
    const updatedBrands = [...brands];
    updatedBrands.splice(index, 1);
    setBrands(updatedBrands);
  };






  
  const handleSendData = () => {

    const valuesArray = subcategories.map((object) => object.value.trim());


    if(containsUndefinedOrEmpty(brands)){
      alert('check brand')
    }else if (containsUndefinedOrEmpty(valuesArray)){
      alert('check sous categorie')
    }else if (isEmptyArray(selectedFiles)){
      alert('check image')
    }else if(name.trim()===''){
      alert("check name categorie")
    }else{
      const formData = new FormData();
    formData.append('image', selectedFiles[0]);
    formData.append('categorieName', name);
    formData.append('brands', JSON.stringify(brands));
    formData.append('valuesArray', JSON.stringify(valuesArray));

      axios.post('https://back.force-informatique.dz/insertCat', formData
          )
          .then((response) => {
            if(response.status=== 200){
              navigate('/Dashboard/Dashboard');
              alert('Data Added correctly')
            }else{
              navigate('/Dashboard/serverError');
            }
          })
          .catch((error) => {
            setSelectedFiles([])
            alert("Erreur de serveur")
          });
    }

    
  };



  return (
    <div>
      <div className="headerAddProduct">
        <p id="addProductTxt">Add Categorie</p>
        <div className="headerAddProductCA">
          <Link to="/Dashboard/Dashboard" id="textGris">
            Cancel
          </Link>
          <div onClick={handleSendData}>
            <p>Add</p>
            <img src={addIcon} alt="" />
          </div>
        </div>
      </div>

      <div className="inputsAddProduct">
        <div className="inputImages">
          <div className={`${viewImages ? `viewImage` : `inputAdd`}`}>
            <img src={imageAdd} alt="" />
            <p>
              Add images from your device{' '}
              <strong onClick={() => fileInputRef.current.click()}>Browse</strong>
            </p>
            <input
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={handleFileChange}
              multiple
            />
          </div>

          <div className={`${viewImages ? `imagesProducts` : `viewImage`}`}>
            {selectedFiles.map((file, index) => (
              <div key={index} className="imageContainerInputs">
                <div className="inputFile">
                  <img
                    className="imageProduct"
                    key={index}
                    src={URL.createObjectURL(file)}
                    alt={`Selected  ${index}`}
                  />
                </div>
                <div className="addDelete">
                  <img
                    src={remove}
                    alt=""
                    className="delete"
                    onClick={() => handleButtonClick(index)}
                  />
                  <hr />
                  <p className="add" onClick={() => fileInputRef.current.click()}>
                    +
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="inputContainerAdds">
          <div className="elementsRow">
            <div className="imputContainer">
              <p className="textInput">Name</p>
              <input placeholder="Ex: Asus" type="text" value={name} onChange={(e)=>{setName(e.target.value)}}/>
            </div>
          </div>
          <div className="elementsRow">
            {subcategories.map((item, index) => (
              <div key={item.id} className="imputContainer imputContainerP">
              {index !== 0 && (
                <img
                  src={suppcat}
                  alt=""
                  className="suppcat"
                  onClick={() => removeSubcategory(item.id)}
                />
    )}
                
                <p className="textInput">Sous catégorie</p>
                <input
                  placeholder="Ex: ZenBook Duo 14"
                  type="text"
                  value={item.value}
                  onChange={(e) => changeSubcategory(item.id, e.target.value)}
                  required
                />
              </div>
            ))}
            <div className="addSous" onClick={addSubcategory}>
              <p>Ajouter une autre sous catégorie</p>
            </div>
          </div>
        </div>
        <div>
          <div className="elementsLigne">
            <div className="imputContainer">
              <p className="textInput">Brand</p>
              <input
                onKeyPress={handleKeyPress}
                onChange={changeBrand}
                placeholder="URL de la fiche technique"
                type="text"
                value={brand}
              />
            </div>
          </div>
          <div className="brandsContainer">
            {brands.map((brand, index) => (
              <div key={index} className="brandContainer">
                <p>{brand}</p>
                <img src={x} alt="" onClick={() => removeBrand(index)} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
            }