import React, { useEffect, useState } from 'react'
import {useParams,Link} from 'react-router-dom'
import axios from 'axios'

import SideBar from '../Componant/SideBar'

import eye from '../assets/eye.svg'




export default function CommandDetail() {



    const [data, setData] = useState([]);

    const { id } = useParams();

    const serverBaseUrl = 'https://back.force-informatique.dz';


    useEffect(() => {
        axios.get(`https://back.force-informatique.dz/allCommandelement?id=${id}`)
        .then((response) => {
          // Handle the response from the backend here
            setData( response.data);

        })
        .catch((error) => {
          console.error('Error:', error);
        });
        
      }, []); 
      console.log(data)

  return (
    <div className='dashboardContainer'>
    <SideBar/>
    <div className='containerTableCommandDetails'>
    <div>
        <p className='titleTables'>All Command</p>
        <table>
            <thead>
                <tr>
                    <th>image</th>
                    <th>Categorie</th>
                    <th>Sous categorie</th>
                    <th>Model</th>
                    <th>Brand</th>
                    <th>Quantite</th>
                    <th>Price</th>
                    <th></th>
                </tr>
            </thead> 
            <tbody>
            { data.length>0 ? data.map((item, index) => (
                <tr key={index}>
                    <td><img className='imageProduct' src={serverBaseUrl + '/uploads/' + item.productImagePath} alt=''/></td>
                    <td>{item.categorie_name}</td>
                    <td>{item.type}</td>
                    <td className='descTable'>{item.model}</td>
                    <td>{item.brand}</td>
                    <td>{item.quantite}</td>
                    <td>{(item.prix - item.prix*item.promotion/100)*item.quantite}</td>
                    <td className='images'>
                      <Link to={`/Dashboard/DetailProductAdmin/${item.idproduct}`}><img className='eyetable' src={eye} alt='eye' key={item.idproduct}/></Link> 
                    </td>
                </tr>
                    ))
                    :
                    <tr><td>no Data</td></tr>}
            </tbody>
        </table>
    </div>
</div>
</div>
  )
}
