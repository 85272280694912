import React,{useEffect, useState,useRef} from 'react'
import {  useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

import '../Componant Style/searchBar.css'

import mark from '../assets/mark.png'

export default function SearchBar(props) {
 
    const location = useLocation();
    const navigate = useNavigate();


    const [cat, setCat] = useState([])
    const [sousCat, setSousCat] = useState([])
    const [brands, setBrands] = useState([])
    const [CatSelected, setCatSelected] = useState("")
    const [sousCatSelected, setSCS]= useState([])
    const [brandSelected, setBS]= useState([])
    const selectRef2 = useRef(null);
    const [dataSearch, setDataSearch] = useState({
        categorie:'',
        sousCategorie:'',
        model:"",
        brand:'',
        Promotion:"",
        Prix:0
    })

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDataSearch((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
      };
  
    


    let selectedValue 

    const handleSelectChange = (event) => {
        selectedValue = event.target.value;
        navigate(`/Product/${selectedValue}`);
    };

    

    useEffect(() => {
        const fetchData = async () => {
          try {
            // First Axios GET request
            const response1 = await axios.get('https://back.force-informatique.dz/getCategorie');
            setCat(response1.data);
      
            // Second Axios GET request
            const response2 = await axios.get('https://back.force-informatique.dz/getSousCategories');
            setSousCat(response2.data)
      
            const response3 = await axios.get('https://back.force-informatique.dz/getBrand');
            setBrands(response3.data)
      
          } catch (error) {
            // Handle errors for both requests here
            console.error('Error fetching data:', error);
          }
        };
      
        fetchData(); // Call the fetchData function
      },[]);

      useEffect(() => {
        if(cat.find(item => item.categorie_name === location.pathname.replace(/%20/g, ' ').replace(/\/Product\//g, ''))){
            setDataSearch((prevData) => ({
                ...prevData,
                categorie: location.pathname.replace(/%20/g, ' ').replace(/\/Product\//g, '')
            }));
            
            setSCS(sousCat.filter(item => item.categorie_name === dataSearch.categorie));
            setBS(brands.filter(item => item.categorie_name === dataSearch.categorie))
        }else if(sousCat.find(item => item.sous_categorie_name === location.pathname.replace(/%20/g, ' ').replace(/\/Product\//g, ''))){
            
            const selectedSubcategory = sousCat.find(item => item.sous_categorie_name === location.pathname.replace(/%20/g, ' ').replace(/\/Product\//g, ''));

            
            const filteredSousCategorie = sousCat.filter(item => item.categorie_name === dataSearch.categorie);
            setBS(filteredSousCategorie);

            // Filter brands based on the categorie_name of the selected subcategory
            const filteredBrands = brands.filter(item => item.categorie_name === dataSearch.categorie);
            setBS(filteredBrands);

            setDataSearch((prevData) => ({
                ...prevData,
                categorie: selectedSubcategory?.categorie_name,
                sousCategorie: selectedSubcategory
            }));

           
            

        }else{
            setDataSearch((prevData) => ({
                ...prevData,
                categorie: CatSelected,
                sousCategorie: 'Tous'
            }));
            setSCS(sousCat.filter(item => item.categorie_name === CatSelected));
            setBS(brands.filter(item => item.categorie_name === CatSelected))
        }
      }, [CatSelected,sousCatSelected,location.pathname]);



const handleSelectChangeCat = (event) => {
        setCatSelected(event.target.value);
};
  
const handleSelectInputChange = (event) => {

    handleSelectChangeCat(event)
    handleSelectChange(event)
  }

  const handleClickCherche = () => {
    const fetchData = async () => {
      try {

        // Encode search parameters
        const encodedCategorie = encodeURIComponent(dataSearch.categorie);
        const encodedSousCategorie = encodeURIComponent(dataSearch.sousCategorie);
        const encodedModel = encodeURIComponent(dataSearch.model);
        const encodedBrand = encodeURIComponent(dataSearch.brand);
        const encodedPromotion = encodeURIComponent(dataSearch.Promotion);
        const encodedPrix = encodeURIComponent(dataSearch.Prix);

        console.log(encodedCategorie+encodedSousCategorie+encodedBrand)
  
        // Construct the search URL with encoded parameters
        const searchUrl = `https://back.force-informatique.dz/search?categorie=${encodedCategorie}&sousCategorie=${encodedSousCategorie}&brand=${encodedBrand}`;
  
        // Fetch data using the constructed URL
        const response8 = await axios.get(searchUrl);
        props.searchFunction(response8.data);
  
      } catch (error) {
        // Handle errors for both requests here
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  };
  return (
    <div className="searchBar_container ">
                <div className="search_bar">
                    <div className='form'>
                    <div className="title_search">
                        <img src={mark} alt=""/>
                        <p>Recherche de </p>
                        <div className='catScat'>
                            <div className="EtatSearch">
                                <select className='searchbarSelect' value={dataSearch.categorie} name="categorie"  onChange={handleSelectInputChange} >
                                <option value='Tous'>Choisir la categorie</option>
                                <option value='Tous'>Tous</option>
                                { cat.map((item, index) => (
                                    <option key={index} value={item.categorie_name}>{item.categorie_name}</option>
                                    ))}
                                </select>          
                            </div>

                            <div className="EtatSearch">
                                <select ref={selectRef2} className='searchbarSelect' name="sousCategorie" value={dataSearch.sousCategorie} onChange={handleSelectChange}>
                                    <option value=''>Choisir la sous categorie</option>
                                    { sousCatSelected.map((item, index) => (
                                    <option key={index} value={item.sous_categorie_name}>{item.sous_categorie_name}</option>
                                    ))}
                                </select>          
                            </div>
                        </div>
                    </div>
                    { location.pathname==="/Product/Choisir%20la%20categorie" || location.pathname==="/Product/Tous" ?
                        (
                            <div className="Choose">
                                <p className='choisirPcImp'>Choisir la categorie</p>
                            </div>
                        ):(<ul className="list_search">
                            <li>
                                <input id="type" type="text" placeholder="Model" value={dataSearch.model} name="model" onChange={handleInputChange}/>
                            </li>

                            <hr className="hrSearch"/>

                            <li>
                                <p>Brand</p>
                                <select id="prix" value={dataSearch.brand} name="brand" onChange={handleInputChange}>
                                <option value=''>Choisir le Brand</option>
                                { brandSelected.map((item, index) => ( 
                                    <option key={index}  value={item.brand_name}>{item.brand_name}</option>
                                ))}
                                <option style={{display:'none'}}>selectionnnnnnn</option>
                                </select>
                                  
                            </li>

                            <hr className="hrSearch"/>
                                <li>
                                    <p>Promotion</p>
                                    <select id="prix" value={dataSearch.Promotion} name="Promotion" onChange={handleInputChange}>
                                        <option>Choisir</option>
                                        <option value="oui">Oui</option>
                                        <option value="non">Non</option>
                                        <option  style={{display:'none'}}>selectionn</option>
                                    </select>
                                </li>
                            <hr className="hrSearch"/>

                            <li>
                                <p>Prix Max</p>
                                <div className="prix">
                                    <input id="prix" type="number" placeholder="100.000.000" value={dataSearch.Prix} name="Prix" onChange={handleInputChange}/>
                                    <p>DA</p>
                                </div>
                            </li>

                            <button className="searchBarButton" type="submit" onClick={handleClickCherche}>Chercher</button>
                        </ul>)}
                    </div>
                </div>
            </div>
  )
}
