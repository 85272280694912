import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'

import '../Componant Style/sidebar.css'


import logo from '../logo.svg'
import arrDown from '../assets/arrow-down.svg'
import arrUp from '../assets/arrow-up.svg'
import command from '../assets/command.svg'
import panel from '../assets/panell.svg'
import deconnect from '../assets/deconnecter.svg'
import ellipse from '../assets/ellipse.svg'
import ellipseW from '../assets/ellipseW.svg'
import add from '../assets/plus.svg'


export default function SideBar() {

  const fileInputRef = useRef(null);

    const location = useLocation();

    const serverBaseUrl = 'https://back.force-informatique.dz';


    function normalizePath20(path) {
      // Replace %20 with a space
      const normalizedPath = path.replace(/%20/g, ' ')
      return normalizedPath;
    }
    
    function normalizePath(path) {
      // Replace %20 with a space
      const normalizedPath = path.replace(/%20/g, ' ').replace(/\/Dashboard\//g, '')
      return normalizedPath;
    }


    const handleClick = () => {
      fileInputRef.current.click();
    };
  

    const normalizedPath = normalizePath20(location.pathname);

    const [cat, setCat] = useState([])
    const [sousCat, setSousCat] = useState([])

    const [categorie, setCategorie] = useState("")

    const [categoryNames, setCategoryNames] = useState([]);



    const navigate = useNavigate();

    const handleSelectChange = (event) => {
        const selectedValue = event.target.id;
        navigate(`/Dashboard/${selectedValue}`);
    };
 

    const isActiveClair = (pathname) => {
        return pathname === normalizedPath ? 'active-linkk' : '';
    };

    const isActive = (pathname) => {
        return pathname === normalizedPath ? 'activee-link' : 'notActibe';
    };

    const linkActive =(pathname)=>{
        return pathname === normalizedPath ? ellipseW : ellipse
    }

    const handleFileChange = async (event) => {
      const file = event.target.files[0];
      if (file && file.type === 'application/pdf') {
        console.log('PDF file selected:', file.name);

        try {
          const formData = new FormData();
          formData.append('pdfFile', file);
  
          // Send the file to the backend using Axios
          const response = await axios.post('https://back.force-informatique.dz/insertPDF', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
  
          // Handle response from the backend
          if(response.status=== 200){
            navigate('/Dashboard/Dashboard');
            alert('Data Added correctly')
          }else{
            navigate('/Dashboard/serverError');
          }
        } catch (error) {
          // Handle error
          console.error('Error uploading file:', error);
        }

  
      } else {
        console.log('Please select a PDF file.');
      }
    };
  

    const handleLogout = () => {
        // Delete the item from localStorage
        localStorage.removeItem('jwt');
    
        // Navigate to the login page
        navigate('/login');
      };

      useEffect(() => {
        const fetchData = async () => {
          try {
            // First Axios GET request
            const response1 = await axios.get('https://back.force-informatique.dz/getCategorie');
            setCat(response1.data);
      
            // Second Axios GET request
            const response2 = await axios.get('https://back.force-informatique.dz/getSousCategories');
            setSousCat(response2.data)
      
            const names = response1.data.map((category) => category.categorie_name);
            setCategoryNames(names);
          } catch (error) {
            // Handle errors for both requests here
            console.error('Error fetching data:', error);
          }
        };
      
        fetchData(); // Call the fetchData function

      },[]);

      useEffect(()=>{
        const result = sousCat.find(item => item.sous_categorie_name === normalizePath(location.pathname)); 
        if (result) {setCategorie(result.categorie_name)}

      },[location.pathname])

      const toggleCategoryName = useCallback(
        (categoryName) => {
          setCategoryNames((prevCategoryNames) => {
            if (prevCategoryNames.includes(categoryName)) {
              // If category name is already in the array, remove it
              return prevCategoryNames.filter((name) => name !== categoryName);
            } else {
              // If category name is not in the array, add it
              return [...prevCategoryNames, categoryName];
            }
          });
        },
        [] // Empty dependency array indicates that the function doesn't depend on any props or state
      );
    
  
  return (
    <div className='sideBarCoContainer'>
      <div className='sidedBarContainer'>

            <Link to='/'> <img id='logoSideBar' src={logo} alt=''/> </Link>

            <div className='sideBar'>

              <div className='sideBarItems'>

                <ul className='sideBarList'>

                  { cat.map((item, index) => (
                  <li key={index} className='sideBarItem'>

                    <div onClick={() => {toggleCategoryName(item.categorie_name);}}  className={`item ${categorie === item.categorie_name? 'active-linkk' : 'noActive-linkk'}`} >
                        <img className='iconsSideBar' src={serverBaseUrl + '/uploads/' + item.icon} alt=''/>
                        <div>
                          <p>{item.categorie_name}</p>
                          <img className='arrowIcon'  src={categoryNames.includes(item.categorie_name) ? arrDown:arrUp} alt=''/>
                        </div>
                    </div> 

                    
                      <ul  className={`ListInsideList ${categoryNames.includes(item.categorie_name) ? 'hidden' : 'visible'}`}>
                      { sousCat.map((sousitem, sousindex) => (
                        ( sousitem.categorie_name === item.categorie_name ? <li key={sousindex}><p id={sousitem.sous_categorie_name} onClick={handleSelectChange} className={`linkInsideList ${isActive(`/Dashboard/${sousitem.sous_categorie_name}`)}`}><img id={sousitem.sous_categorie_name} onClick={handleSelectChange}  src={linkActive(`/Dashboard/${sousitem.sous_categorie_name}`)} alt=''/>{sousitem.sous_categorie_name}</p></li> : null)
                          ))}
                      </ul>
                    

                  </li>
                  ))}

                  <li className={`sideBarItem `}>
                    <Link  to="/Dashboard/Panneau" >
                        <div className={`item ${isActiveClair("/Dashboard/Panneau")}`}>
                            <img className='iconsSideBar' src={panel} alt=''/>
                            <div>
                              <p>Panneau</p>
                            </div>
                        </div>
                    </Link>
                  </li>

                  <li className='sideBarItem'>
                    <Link to="/Dashboard/Commandes">
                        <div className={`item ${isActiveClair("/Dashboard/Commandes")}`}>
                            <img className='iconsSideBar' src={command} alt=''/>
                            <div>  
                              <p>Commandes</p>
                            </div>
                        </div>
                    </Link>
                  </li>
                </ul>

              </div>

              <div className='addcat_deco'>
                <div className='addCat' onClick={handleClick}>
                <input
                  type="file"
                  accept=".pdf" 
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
                  <img src={add} alt=''/>
                  <p>ajouter PDF</p>
                </div>
                <Link to='/Dashboard/addCat' className='addCat'>
                  <img src={add} alt=''/>
                  <p>Ajouter categorie</p>
                </Link>
                <div onClick={handleLogout} className='deconnecter'>
                  <img src={deconnect} alt=''/>
                  <p>Déconnecter</p>
                </div>
              </div>


            </div>
          </div>
      </div>

  )
}
