import React,{useRef} from 'react'
import Header from '../Componant/Header'
import Footer from '../Componant/Footer'

import '../Page Style/propos.css'
import { Link } from 'react-router-dom'

import iconService from '../assets/iconProposService.svg'
import mainImagePropos from '../assets/mainImageService.svg'
import secondImagePropos from '../assets/secondImageService.svg'
import forceBanner from '../assets/forceBanner.svg'
import partenaire from '../assets/partenaire.svg'

export default function Propos() {

  const scrollToRef = useRef(null);

  const scrollTo = () => {
    scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className='proposContainer'>
    <Header/>
      <div className='firstSectionPropos'>
        <div className='allTextFirstSectionPropos'>
          <div className='textOnePropos'>
            <div className='insideTextOnePropos'>
              <div className='blueSide'></div>
              <p>Qu'est-ce que Force informatique ? et Quelle est sa validité</p>
            </div>
            <div><p className='discriptionPropos'>Force informatique occupe une place prépondérante dans le marche grace à son équipe qui a amassé 20 ans d'expérience dans le domaine informatique.</p></div>
          </div>
          <div className='buttonContainerProps'>
            <Link to ='/Product/Tous' id='nosProduit'>Nos produits</Link>
            <p id="contacterNousPropos" onClick={scrollTo}>Contacter nous</p>
          </div>
        </div>
      </div>

      <div className='secondSectionPropos'>
        <div className='contentOneSecondSectionPropos'>
          <div></div>
          <div id='mainImagePropos'><img src={mainImagePropos}/></div>
          <div id='secondImagePropos'><img src={secondImagePropos}/></div>
        </div>
        <div className='contentTwoSecondSectionPropos'>
          <div className='titesNosServicePropos'>
            <div  className='serviceIconPropos'>
              <img src={iconService}/>
              <p>Services</p>
            </div>
            <p id='nosService'>Nos services</p>
          </div>
          <div className='itemsServiceProps'>
            <div className='itemServiceProps'>
              <div></div>
              <p>Etude, Conseille et proposition de solutions sur mesure</p>
            </div>
            <hr/>
            <div className='itemServiceProps'>
              <div></div>
              <p>Vente de matériel informatique bureautique et mobilier de bureau.</p>
            </div>
            <hr/>
            <div className='itemServiceProps'>
              <div></div>
              <p>Installation d'équipements informatiques, réseau, système d'alarme et vidéosurveil-lance.</p>
            </div>
            <hr/>
            <div className='itemServiceProps'>
              <div></div>
              <p>Contrat de maintenance préventive et correctve.</p>
            </div>
            <hr/>
            <div className='itemServiceProps'>
              <div></div>
              <p>Contrat de vente.</p>
            </div>
            <hr/>
            <div className='itemServiceProps'>
              <div></div>
              <p>Livraison vers les 58 wilay.</p>
            </div>
            <hr/>
            <div className='itemServiceProps'>
              <div></div>
              <p>Service après-vente.</p>
            </div>
          </div>
        </div>
      </div>

      <div ref={scrollToRef} className='bannerImage'>
        <img src={forceBanner}/>
      </div>

      <div className='ligneNosClientPropos'>
        <hr/>
        <p>Nos meilleurs clients</p>
      </div>
      <div className='paddingPartenaire'>
        <img src={partenaire}/>
      </div>
    <Footer/>
    </div>
  )
}
