import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import promotion from '../assets/promotion.svg'
import '../Componant Style/Product.css'

import addTo from '../assets/addTo.svg'

export default function Product(props) {

  const [truth,setTruth] = useState(false)


  const serverBaseUrl = 'https://back.force-informatique.dz/';


  useEffect(()=>{ 
    if(props.pro==="Nos Promotions" || props.pro > 0){
      setTruth(true)
    }else{
      setTruth(false)
    } 
  },[props.pro])
 


  const handleAddCommand = (value) => {
    const existingArrayString = localStorage.getItem('id');
    const existingArray = existingArrayString ? JSON.parse(existingArrayString) : [];
  
    // Check if the value exists in the array
    const existingItem = existingArray.find(item => item.id === value);
  
    if (!existingItem) {
      // If the value exists, increment the quantite property
      existingArray.push({ id: value, quantite: 1 });
    }
    const updatedArrayString = JSON.stringify(existingArray);
    localStorage.setItem('id', updatedArrayString);
    props.getNumberOfElementInPanel();
  
    if (existingItem) {
      alert("Product Exister Déjà!");
    } else { 
      alert("Product Ajouter Correctement!");
    }
  };

  return (
    <div className= {truth? "Product":"promotionProductBorder"}>

            <div className='imagesProduct'>
                <img src={serverBaseUrl + '/uploads/' + props.image} alt='' className='productimg'/>
                {truth && <> <img src={promotion} alt='' className='promotionimg'/>
                <p>-{props.promotion}%</p> </>}
            </div>

            <div className='infoProduct'>

                <Link className='nameProduct' to={`/ProductDetail/${props.id}`}>{props.brand} {props.model}</Link>

                <div className='priceAdd'>

                    <div className={truth?"price":"promotionProductPrice"}>{Math.round(props.prix - props.prix*props.promotion/100)}.00 DA</div>

                    <div className='add' onClick={() => handleAddCommand(props.id)}>
                        <img src={addTo} alt=''/>
                        <p>Ajouter </p>
                    </div>

                </div>

            </div>

    </div>
)

}
