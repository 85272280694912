import React, {useState} from 'react'
import Header from '../Componant/Header'
import Footer from '../Componant/Footer'
import "../Page Style/entreprise.css"

import axios from 'axios'

import { Link } from 'react-router-dom'

import imageEspace from '../assets/imageEspace.svg'
import iconDownlaod from '../assets/iconDownlaod.svg'

export default function Entreprises() {

  const[password, setPassword] = useState('')

  const handleChange = (event) => {
    setPassword(event.target.value);
  };

  const isValidate = () =>{
      if(!password){
        return false
      }
      return true
  }

  const handleClick = async () => { // Define the function as async
    const validate = isValidate();
    if (validate) {
      if (password === 'forceInfo2001') {
        try {
          // Make a request to backend to get the PDF path
          const response = await axios.get('https://back.force-informatique.dz/getPDFPath');
          
          
          if(response.data.message==='PDF not found'){alert('PDF not found')}else{ 
            const { pdfPath } = response.data; window.location.href = "https://back.force-informatique.dz/uploads/"+pdfPath;}
          setPassword("");
        } catch (error) {
          console.error('Error downloading PDF:', error);
        }
      } else {
        alert("Le mot de passe incorrect");
      }
    } else {
      alert("Remplie le champs de mot de passe");
    }
  };
  
  return (
    <div className='containerEspace'>
    <Header/>
    <img id='imageEspace' src={imageEspace}/>
    <div className='insideContainerEspace'>
      <p id='titleEspace'>Bienvenue sur l’epace grossistes</p>
      <p id='descriptionEspace'>Étes-vous un grossiste ? Pour accéder à notre liste des produits, vous pouvez utiliser le mot de passe que nous avons fourni pour accéder à notre liste. Cette liste sera automatiquement téléchargée sur votre ordinateur sous forme de PDF.</p>
      <div className='inputEspaceContainer'>
        <div className='inputEspace'>
          <input placeholder='Mot de pass' type='password'   value={password} onChange={handleChange}/>
          <div className='divDownlaod' onClick={handleClick}>
            <img src={iconDownlaod}/>
          </div>
        </div>
      </div>
      <p>Si vous souhaitez être parmi nos clients, vous avez la possibilité de nous contacter afin d'obtenir l'accès à cette partie.</p>
      <div className='contacterEspaceContainer'><Link to='/' id='contacterEspace'>Contacter nous</Link></div>
    </div>
    <Footer/>
    </div>
  )
}
