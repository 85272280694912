import React, { useState,useEffect } from 'react'
import {useParams} from 'react-router-dom'
import axios from 'axios'

import '../Page Style/DetailProduct.css'

import Header from '../Componant/Header'
import Footer from '../Componant/Footer'

import panel from '../assets/panelDetailCar.png'

export default function DetailProduct() {

  const serverBaseUrl = 'https://back.force-informatique.dz';

  const [imagePrincipe, setImagePricipe] = useState()
  const [arrayImage, setArryImage ]=useState ([])

  const { productId } = useParams();
  const [data, setData] = useState({})
  const [dataDetail, setDataDetail] = useState({})
  const [arrayDesc, setArrayDesc] = useState([])


  useEffect(() => {

      const fetchData = async () => {
        window.scrollTo(0, 0);
        try {
          // First Axios GET request
          const response = await axios.get(`https://back.force-informatique.dz/DetailProduct?id=${productId}`)
          setData(response.data);
          setDataDetail(response.data[0]);
          setArrayDesc(response.data[0].description.split(/%%%/));
          setImagePricipe(response.data[0].productImagePath);
          
          const response1 = await axios.get(`https://back.force-informatique.dz/DetailProductImages?id=${productId}`)
          if (Array.isArray(response1.data)) {
            setArryImage(response1.data.map(item => item.path));
          } else {
            console.error('Error: Response data is not an array');
          }
          
        } catch (error) {
          // Handle errors for both requests here
          console.error('Error fetching data:', error);
        }
      };
    
      fetchData();
  
  }, []);

  const handleChangeImage = (index) => {

    const newArrayImage = [...arrayImage];
    
   newArrayImage[index] = imagePrincipe
   setImagePricipe(arrayImage[index]);

   setArryImage(newArrayImage)
  }

  const handleAddCommand = (value) => {
    const existingArrayString = localStorage.getItem('id');
    const existingArray = existingArrayString ? JSON.parse(existingArrayString) : [];
  
    // Check if the value exists in the array
    const existingItem = existingArray.find(item => item.id === value);
  
    if (!existingItem) {
      // If the value exists, increment the quantite property
      existingArray.push({ id: value, quantite: 1 });
    }
    const updatedArrayString = JSON.stringify(existingArray);
    localStorage.setItem('id', updatedArrayString);
  
    if (existingItem) {
      alert("Product Exister Déjà!");
    } else {
      alert("Product Ajouter Correctement!");
    }
  };

  return (
    <div>
      <Header/>
      <div className='detailProductClient'>
        <p id='detailProductName'>{dataDetail.brand} {dataDetail.model} {dataDetail.processeur && <i>/ {dataDetail.processeur} {dataDetail.generation}</i>} </p>
        <div className='detailProductinfo'>
          <div className='detailProductinfoImgs'>
            
            <img id='principale' src={serverBaseUrl + '/uploads/' + imagePrincipe}/>
            <div className={`otherImages`}>
              {arrayImage && arrayImage.map((item, index) => (
                <img src={serverBaseUrl + '/uploads/' + item} onClick={ ()=>handleChangeImage(index)} key={index} alt="images"/>
              ))}
            </div>
          </div>
          <div className='detailProductinfoData'>
            <div className='descContainer'>
              <p id='descTitle'>Description</p>
              <div id='containerDescriptionP'>
              {arrayDesc && arrayDesc.map((item, index) => (
                    <p key={index}>{item}</p>
                  ))}
              </div>
            </div>

            <div className='fichetechPrixPanel'>
            <a href={dataDetail.ficheTechnique?.startsWith('http') ? dataDetail.ficheTechnique : `http://${dataDetail.ficheTechnique}`} target='_blank'>
                    Fiche technique
                 </a>
              <div className='pricePanel'>
                <div className='price'>
                {dataDetail.promotion > 0 ? <p id='prixTT'>{dataDetail.prix} DA</p> : null}
                  <p id='prixPromo'>{dataDetail.prix - dataDetail.prix * dataDetail.promotion/100} DA</p>
                </div>
                <div className='addTopanel' onClick={() => handleAddCommand(dataDetail.idproduct)}>
                    <img src={panel}/>
                    <p>Ajouter au panier</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}
 